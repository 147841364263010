<template>
  <ValidationObserver
    tag="form"
    v-if="!submitted"
    ref="observer"
    class="form"
    @submit.prevent="validateBeforeSubmit()">

    <!-- title -->
    <h4>次の項目を入力して「同意し確認画面へ」をクリックしてください。<br>
      （利用規約・個人情報の取り扱いについてへの同意が必要です。）</h4>

    <!-- Error server -->
    <ul v-if="errors.length"
        class="error__server">
      <li>{{ errors[0] }}</li>
    </ul>

    <div class="form__contact">
      <div class="form__contact__group">
        <!-- email -->
        <div class="form__control">
          <label>
            メールアドレス
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <InputField v-model="form.email"
                        class="w-100"
                        rules="required|email|max:200"
                        field="メールアドレス"
                        vid="email"
                        :disabled="(profile && profile.email) ? true : false"/>
          </div>
        </div>

        <!-- content contact -->
        <div class="form__control">
          <label>
            お問い合わせ内容
            <span class="required"
                  v-text="'必須'"/>
          </label>

          <div class="form__control__input">
            <span class="required"
                  v-text="'必須'"/>

            <TextAreaField v-model="form.content"
                        rules="required|max:2000"
                        class="w-100"
                        field="お問い合わせ内容"
                        vid="content"/>
          </div>
        </div>
      </div>
    </div>

    <!-- TERM AND POLICY -->
    <TermPolicy />

    <!--confirm rules-->
    <div class="rule">
      <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        name="policy"
        vid="policy"
        rules="required">
        <div class="rule__check">
          <input v-model="read_policy"
                 type="checkbox"
                 id="check"
                 :true-value="true"
                 :false-value="null">
          <label for="check"
                 v-text="'「利用規約」「個人情報の取扱いについて」を確認したので同意します。'"/>
        </div>

        <p v-if="errors[0]"
           class="err__policy">「利用規約」「個人情報の取り扱いについて」を確認してチェックしてください。</p>
      </ValidationProvider>
    </div>

    <!--button submit-->
    <button type="submit"
            class="btn btn__submit">
      同意し確認画面へ
      <ChevronRightIcon size="24"/>
    </button>
  </ValidationObserver>

  <div v-else class="form">
    <!-- title -->
    <h4>入力内容を確認してください。<br>
      内容をご確認の上、よろしければ「送信」ボタンを押してください。</h4>

    <div class="form__contact">
      <div class="form__contact__group">
        <div class="item">
          <label v-text="'メールアドレス'"/>

          <p v-text="form.email"/>
        </div>

        <div class="item has-textarea">
          <label v-text="'お問い合わせ内容'"/>

          <p v-text="form.content"/>
        </div>
      </div>
    </div>

    <!--button submit-->
    <div class="btn__group">
      <a @click.prevent="backToInput"
         href="#"
         class="btn btn__back">
        <ChevronLeftIcon size="24"/>
        入力画面へ戻る
      </a>

      <button @click.prevent="handleSubmit()"
              class="btn btn__submit">
        上記内容で送信する
        <ChevronRightIcon size="24"/>
      </button>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/form/InputField'
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons'
import TextAreaField from '@/components/form/TextAreaField'
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
import TermPolicy from '@/components/TermPolicy'

export default {
  name: 'Form',

  components: {
    TermPolicy,
    TextAreaField,
    InputField,
    ChevronRightIcon,
    ChevronLeftIcon
  },

  mixins: [Common],

  async beforeRouteEnter (to, from, next) {
    if (store.state.auth.token) {
      await store.dispatch('auth/userProfile')
    }
    next()
  },

  computed: {
    ...mapState('auth', ['profile', 'token'])
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      errors: [],
      read_policy: null,
      submitted: false,
      form: {
        email: '',
        content: ''
      },
      hostName: window.location.origin
    }
  },

  created () {
    if (this.profile) {
      this.form.email = this.profile.email
    }
  },

  methods: {
    ...mapActions('contact', ['sendInquiry']),

    backToInput () {
      this.submitted = false
      window.scrollTo({ top: 0 })
    },

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.submitted = true
        // Scroll top
        this.scrollToTop()
      }
    },

    handleSubmit () {
      // handle api
      this.sendInquiry(this.form).then(result => {
        if (result.status === 204) {
          this.$router.push({ name: 'ContactComplete' })

          // Scroll top
          this.scrollToTop()
        } else {
          this.handleErrors(result)
        }
      })
    },

    handleErrors (result) {
      this.errors = []

      if (result.status === 422) {
        // Back to form contact
        this.submitted = false
        // Scroll top
        this.scrollToTop()
        // Set errors server
        result.data.errors.forEach(x => {
          this.errors.push(x.message[0])
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  padding: 53px 84px 70px;
  @media #{$info-tablet-vertical} {
    padding: 53px 40px 70px;
  }
  @media #{$info-phone} {
    padding: 48px 18px;
  }
  h4 {
    @include font-size(18px);
    font-weight: normal;
    text-align: center;
    margin-bottom: 50px;
    @media #{$info-phone} {
      font-size: 18px;
      text-align: left;
    }
  }
  &__contact {
    background-color: #E7E5DE;
    border-radius: 6px;
    margin-bottom: 60px;
    @media #{$info-phone} {
      margin-bottom: 45px;
    }
    &__group {
      padding: 24px;
      @media #{$info-phone} {
        padding: 10px 18px 18px;
      }
      .form__control {
        display: flex;
        align-items: flex-start;
        @media #{$info-phone} {
          display: block;
        }
        label {
          width: 235px;
          flex-shrink: 0;
          @include font-size(18px);
          margin-top: 13px;
          @media #{$info-phone} {
            width: 100%;
            font-size: 16px;
            display: block;
            margin-bottom: 10px;
          }
          .required {
            display: none;
            color: #C66C44;
            @include font-size(14px);
            font-weight: bold;
            font-family: $font-family-title;
            margin-top: 16px;
            margin-left: 8px;
            @media #{$info-phone} {
              display: inline-block;
              font-size: 14px;
              margin-top: 0;
            }
          }
        }
        p {
          margin-top: 16px;
          margin-left: 50px;
          @media #{$info-phone} {
            margin-left: 0;
          }
        }
      }
      .form__control:not(:last-child) {
        margin-bottom: 8px;
        @media #{$info-phone} {
          margin-bottom: 30px;
        }
      }
      .item {
        display: flex;
        align-items: center;
        @media #{$info-phone} {
          display: block;
        }
        label {
          width: 235px;
          flex-shrink: 0;
          @include font-size(18px);
          @media #{$info-phone} {
            width: 100%;
            font-size: 16px;
            display: block;
            margin-bottom: 12px;
          }
        }
        p {
          flex: 1;
          border-radius: 6px;
          background: rgba(255,255,255,.35);
          color: #282828;
          font-family: $font-family-title;
          @include font-size(20px);
          padding: 12px 16px;
          @media #{$info-phone} {
            font-size: 16px;
            padding: 10px 18px;
          }
        }
        &.has-textarea {
          align-items: flex-start;
          label {
            padding-top: 16px;
          }
          p {
            min-height: 206px;
            word-break: break-all;
            white-space: break-spaces;
            @media #{$info-phone} {
              min-height: 220px;
            }
          }
        }
      }
      .item:not(:last-child) {
        margin-bottom: 18px;
        @media #{$info-phone} {
          margin-bottom: 30px;
        }
      }
      .form__control__input {
        display: flex;
        align-items: flex-start;
        flex: 1;
        .required {
          color: #C66C44;
          @include font-size(14px);
          font-weight: bold;
          flex-shrink: 0;
          margin-right: 24px;
          margin-top: 16px;
          @media #{$info-phone} {
            display: none;
          }
        }
      }
    }
  }

  .rule {
    background: #E7E5DE;
    border-radius: 6px;
    text-align: center;
    padding: 34px 24px 33px;
    margin-bottom: 54px;
    @media #{$info-phone} {
      padding: 35px 18px;
      font-size: 18px;
      text-align: left;
    }
    .rule__check {
      display: flex;
      align-items: flex-start;
      input {
        flex-shrink: 0;
        margin-right: 5px;
      }
      input:after {
        margin-top: 7px;
        @media #{$info-phone} {
          margin-top: 9px;
        }
      }
    }
    p {
      margin-bottom: 13px;
      @media #{$info-phone} {
        padding-bottom: 18px;
        border-bottom: 1px solid #ffffff;
      }
    }
    .err__policy {
      color: #cd201f;
      font-size: 12px;
      margin: 10px 0 0;
      @media #{$info-phone} {
        padding-bottom: 18px;
        border-bottom: 1px solid #ffffff;
      }
    }
  }

  .btn__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    letter-spacing: 2px;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;
    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
      max-width: 100%;
    }
    svg {
      margin-left: 26px;
      @media #{$info-phone} {
        margin-left: 15px;
      }
    }
  }

  .btn__group {
    position: relative;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .btn__back {
      position: absolute;
      top: 12px;
      display: flex;
      align-items: center;
      @include font-size(16px);
      font-family: $font-family-title;
      color: #282828;
      @media screen and (max-width: 992px) {
        position: static;
        margin-top: 30px;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
}
</style>
